import Login from "../Login";
//import "../Login/login.module.css";

function LoginPage() {
  return (
    <div className="login-box">
      <Login />
    </div>
  );
}
export default LoginPage;