import axios from 'axios';
const url = process.env.REACT_APP_BACKEND_BASE_URL
// Function to increment the message counter for a given session
export const incrementMessageCounter = async ({ sessionId, email, fingerprint, version }) => {
  try {
    const response = await axios.post( url +'/incrementMessageCounter', {
      sessionId,
      email,
      fingerprint,
      version
    });
    return response;
  } catch (error) {
    console.error('Error incrementing message counter:', error);
    throw error;
  }
};