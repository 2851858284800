import Signup from "../Signup";
//import Styles from "../Signup/signup.module.css";

function SignupPage() {
  return (
    <div className="login-box">
      
      <Signup />
    </div>
  );
}
export default SignupPage;
