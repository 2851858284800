import React, { useState } from "react";
import Google_icon from "../../assets/icons/svg/google.svg";
import styles from '../../Components/Signup/signup.module.css';


const Signupbox = () => {
  const [activeField, setActiveField] = useState(null);
  const [inputValues, setInputValues] = useState({ email: '', password: '' });

  const handleFocus = (field) => {
    setActiveField(field);
  };

  const handleBlur = (field) => {
    // Only remove activeField if the field is empty
    if (!inputValues[field]) {
      setActiveField(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <>
      <div className={styles['blur-bg']}></div>
      <div className={styles.loginpopup}>
        <h1>Sign Up</h1>
        <p>Sign up to unlock comprehensive MRO solutions!</p>
        <form>
          <div className={`${styles['form-div']} ${activeField === 'email' || inputValues.email ? styles.active : ''}`}>
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                name="email"
                className={styles['input-validate']}
                id="email"
                required
                onFocus={() => handleFocus('email')}
                onBlur={() => handleBlur('email')}
                onChange={handleChange}
                value={inputValues.email} />
              <span className={styles['error-div']}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 10 10"><path id="Path_4483" data-name="Path 4483" d="M14.921,19.414a4.494,4.494,0,1,0-4.494-4.494,4.494,4.494,0,0,0,4.494,4.494Zm0-2.944a.507.507,0,0,1,.358.864h0a.506.506,0,0,1-.715-.715h0a.5.5,0,0,1,.358-.148ZM14.7,12.358c.294,0,.7-.071.7.253v3.228c0,.324-.4.253-.7.253a.253.253,0,0,1-.253-.253V12.611a.253.253,0,0,1,.253-.253Zm3.757,6.1a5,5,0,1,1,0-7.071A5,5,0,0,1,18.456,18.456Z" transform="translate(-9.921 -9.92)" fill="#e02b27" fill-rule="evenodd"/></svg>Enter valid email address</span>
          </div>

          <div className={`${styles['form-div']} ${activeField === 'password' || inputValues.password ? styles.active : ''}`}>
            <label htmlFor="password">Password*</label>
            <input
              type="password"
              name="password"
              className={styles['input-validate']}
              id="password"
              required
              onFocus={() => handleFocus('password')}
              onBlur={() => handleBlur('password')}
              onChange={handleChange}
              value={inputValues.password} />
          </div>
          
          <button type="submit">Sign Up</button>
        </form>
        <p className={styles['or-signup']}><span>or Sign up with</span></p>
        <button className={styles['continue-google']}><img className={styles.google_icon} src={Google_icon} alt="setting_icon" />Continue with Google</button>
        <p className={styles['already-account']}>Already have an account? <a href="/">Login</a></p>
      </div>
    </>
  );
};
export default Signupbox;
