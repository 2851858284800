import React, { useState } from "react";
import styles from '../../Components/Login/login.module.css';


const Forgotbox = () => {
  const [activeField, setActiveField] = useState(null);
  const [inputValues, setInputValues] = useState({ email: '', password: '' });

  const handleFocus = (field) => {
    setActiveField(field);
  };

  const handleBlur = (field) => {
    // Only remove activeField if the field is empty
    if (!inputValues[field]) {
      setActiveField(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <>
      <div className={styles['blur-bg']}></div> 
      <div className={`${styles.loginpopup} ${styles['forgot-popup']}`}>
        <h1>Forgot Password</h1>
        <p>Don't worry! It happens. Please enter the email or phone number associated with your account.</p>
        <form>
          <div className={`${styles['form-div']} ${activeField === 'email' || inputValues.email ? styles.active : ''}`}>
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              name="email"
              className={styles['input-validate']}
              id="email"
              required
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              onChange={handleChange}
              value={inputValues.email}
            />
            <span className={styles['error-div']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 10 10"><path id="Path_4483" data-name="Path 4483" d="M14.921,19.414a4.494,4.494,0,1,0-4.494-4.494,4.494,4.494,0,0,0,4.494,4.494Zm0-2.944a.507.507,0,0,1,.358.864h0a.506.506,0,0,1-.715-.715h0a.5.5,0,0,1,.358-.148ZM14.7,12.358c.294,0,.7-.071.7.253v3.228c0,.324-.4.253-.7.253a.253.253,0,0,1-.253-.253V12.611a.253.253,0,0,1,.253-.253Zm3.757,6.1a5,5,0,1,1,0-7.071A5,5,0,0,1,18.456,18.456Z" transform="translate(-9.921 -9.92)" fill="#e02b27" fill-rule="evenodd"/></svg>Enter valid email address</span>
          </div>
           
          <button type="submit">Send Code</button>
        </form>
      </div>
    </>
  );
};
export default Forgotbox;
