import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL

export const loginUser = async (email, password) => {
  try {
    // Send data as `application/x-www-form-urlencoded`
    const params = new URLSearchParams();
    params.append("username", email); // Match `form_data.username` in FastAPI
    params.append("password", password); // Match `form_data.password` in FastAPI

    const response = await axios.post(`${API_BASE_URL}/token`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    const { access_token, token_type } = response.data;

    if (token_type === "bearer") {
      // Set the token in cookies with a 5-day expiration
      Cookies.set("authToken", access_token, { expires: 5 });
      return access_token;
    } else {
      throw new Error("Invalid token type received from API.");
    }
  } catch (error) {
    throw error.response?.data?.message || "Login failed. Please try again.";
  }
};
