import React, { useEffect, useState } from "react";
import { sha256 } from "js-sha256";

const VisitorId = () => {
  const [visitorId, setVisitorId] = useState("Generating...");

  useEffect(() => {
    const generateFingerprint = () => {
      // Stable properties
      const userAgent = navigator.userAgent;
      const language = navigator.language || navigator.userLanguage;
      const platform = navigator.platform;
      const colorDepth = window.screen.colorDepth;
      const screenResolution = `${window.screen.width}x${window.screen.height}`;
      const timeZoneOffset = new Date().getTimezoneOffset();
      const devicePixelRatio = window.devicePixelRatio || 1;

      // Network Information API (may not be available in incognito)
      const getConnectionType = () => {
        if (navigator.connection && navigator.connection.effectiveType) {
          return navigator.connection.effectiveType;
        }
        return "unknown";
      };

      const connectionType = getConnectionType();

      // Plugins (limited in incognito mode)
      const getPlugins = () => {
        if (navigator.plugins) {
          return Array.from(navigator.plugins)
            .map((plugin) => plugin.name)
            .join(",");
        }
        return "unknown";
      };

      const plugins = getPlugins();

      // Canvas fingerprinting with error handling
      const getCanvasFingerprint = () => {
        try {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          context.textBaseline = "top";
          context.font = "16px 'Arial'";
          context.fillStyle = "#f60";
          context.fillRect(125, 1, 62, 20);
          context.fillStyle = "#069";
          context.fillText("Your Browser", 2, 15);
          context.fillStyle = "rgba(102, 204, 0, 0.7)";
          context.fillText("Your Browser", 4, 17);
          return canvas.toDataURL();
        } catch (e) {
          return "not_supported";
        }
      };

      const canvasFingerprint = getCanvasFingerprint();

      // Assemble fingerprint data
      const fingerprintData = [
        userAgent,
        language,
        platform,
        colorDepth,
        screenResolution,
        timeZoneOffset,
        devicePixelRatio,
        connectionType,
        plugins,
        canvasFingerprint,
      ].join("###");

      // Hash the fingerprint data using js-sha256
      const hashHex = sha256(fingerprintData);

      setVisitorId(hashHex);
    };

    generateFingerprint();
  }, []);

  return (
    <div className="visitor-id-box">
      <h1>Your Visitor ID</h1>
      <p>{visitorId}</p>
    </div>
  );
};

export default VisitorId;
