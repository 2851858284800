// New function to update guest chats to the logged-in user's email
export async function updateGuestChatsToLoggedInUser(sessionId, newEmail) {
    
    const url = process.env.REACT_APP_BACKEND_BASE_URL
    if (newEmail !== 'guest@user.com') {
      try {
        // Fetch chats with the same sessionId and 'guest@mail.com' email
        // const response = await fetch( url + '/getChatsBySession', {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ sessionId, email: 'guest@mail.com' }),
        // });
        // const guestChats = await response.json();
        // console.log(guestChats);
        
        // if (guestChats && guestChats.length > 0) {              
          await fetch( url + '/updateChats', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId, email: newEmail }),
          });
        }
      // } 
      catch (error) {
        console.error('Error updating guest chats:', error);
      }
    }
  }