import axios from 'axios';
const url = process.env.REACT_APP_BACKEND_BASE_URL
// Fetch visitor data based on visitorId and email
export const fetchVisitorData = async (visitorId) => {  
    const response = await axios.get( url + `/get_visitor_sessions`, {
      params: {
        visitor_id: localStorage.getItem('visitorId'),
      },
    });

    return response.data;
  
// catch (error) {
//     console.error('Error fetching visitor data:', error);
//     throw error; 
//  }
};
