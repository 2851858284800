// src/Components/Pages/AlertBox.jsx

import React from 'react';

const SessionErrorBox = ({ message }) => {
    return (
        <div style={styles.sessionErrorBox}>
            {message}
        </div>
    );
};

const styles = {
    sessionErrorBox: {
        position: 'fixed',
        top: '1%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        backgroundColor: '#ffcccb',
        color: '#333',
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
        zIndex: 1000,
        fontSize: '16px',
    }
};

// Exporting SessionErrorBox component
export default SessionErrorBox;
