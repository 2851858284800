import Forgot from "../Forgot";
import "../Forgot/forgot.module.css";

function ForgotPage() {
  return (
    <div className="login-box">
      <Forgot />
    </div>
  );
}
export default ForgotPage;