import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import RecentChats from './RecentChats'

// D:\chatmro_frontend\chamro_website\src\Components\Pages\MainPage.jsx
function NavigationBar({setSessionId, setConversation }) {  
  const location = useLocation();
  const isV2 = location.pathname.startsWith("/v2");
  const email = localStorage.getItem('email')  
  return (
    
    <div className="NavigationBar">
      <div className="topSection">
        <div className="chatmro">          
          <p>ChatMRO <span>{isV2 ? "v2" : "Beta"}</span></p>  
                   
        </div>
        {/* <img className="back-icon" src={Back_Icon} alt="back_icon_2" /> */}
      </div>
      <div>
      <RecentChats email={email} setSessionId={setSessionId} setConversation={setConversation} />
      </div>
      {/* <div className="NavigationbarList">
        {NavigationBarData.map((val, key) => {
          const isActive = pathname === val.link;
          return (
            <Link
              key={key}
              className={"sidebar-row " + (isActive ? "active" : "")}
              to={val.link}
            >
              <div id="icon">{val.icon}</div>
              <div id="title">{val.title}</div>
            </Link>
          );
        })}
      </div> */}
    </div>
  );
}

export default NavigationBar;
